<template>
  <MyFiles />
</template>

<script>
import { defineComponent } from 'vue';

// Components
import MyFiles from '../components/MyFiles.vue';

export default defineComponent({
  name: 'HomeView',

  components: {
    MyFiles,
  },
});
</script>
