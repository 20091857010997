<template>
    <v-app-bar app color="primary" dark>
      <v-toolbar-title>Fast File</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn to="/">
        <v-icon v-if="isAuthenticated">mdi-file-cabinet</v-icon>
        <v-icon v-else>mdi-login</v-icon>
        {{ isAuthenticated ? "Mis archivos" : "Ingresar" }}
      </v-btn>
      <v-btn to="about">
        <v-icon>mdi-information </v-icon>
       Acerca de
      </v-btn>
      <v-btn  v-if="isAuthenticated" @click="signOut">
        <v-icon>mdi-logout</v-icon>
        Cerrar sesi&oacute;n
      </v-btn>
    </v-app-bar>
  </template>
  
  <script>
  import { getAuth, signOut as firebaseSignOut } from "firebase/auth";
  
  export default {
    name: "AppNavbar",
    computed: {
      isAuthenticated() {
        return !!this.$store.getters.user;
      },
    },
    methods: {
      async signOut() {
        const auth = getAuth();
        try {
          await firebaseSignOut(auth);
          await this.$store.dispatch("logout");
          this.$router.push("auth"); 
        } catch (error) {
          console.error("Error al cerrar sesión:", error);
        }
      },
    },
  };
  </script>
  