import { initializeApp } from 'firebase/app';
import { getStorage } from 'firebase/storage';
import { getAuth } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyB7RBrHIj0Be5F8QLpQcyDsUwBVW9TwgbI",
    authDomain: "fastfile-4cc3a.firebaseapp.com",
    projectId: "fastfile-4cc3a",
    storageBucket: "fastfile-4cc3a.appspot.com",
    messagingSenderId: "964561881464",
    appId: "1:964561881464:web:2c6d3eac19871e87a2608d",
    measurementId: "G-T39SKD7MXZ"
};

const app = initializeApp(firebaseConfig);

const storage = getStorage(app);
const auth = getAuth();

export { storage, auth };

