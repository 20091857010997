import { createStore } from "vuex";
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";

const store = createStore({
    state: {
      user: null
    },
    mutations: {
      setUser(state, user) {
        state.user = user;
        saveState("user", user);
      },
      clearUser(state) {
        state.user = null;
        clearState("user");
      }
    },
    actions: {
      checkUserAuth({ commit }) {
        const auth = getAuth();
  
        onAuthStateChanged(auth, (user) => {
          if (user) {
            commit("setUser", user.uid);
          } else {
          console.log(user);
            const savedUser = JSON.parse(window.localStorage.getItem("user"));
            if (savedUser) {
              commit("setUser", savedUser);
            } else {
              commit("setUser", null);
            }
          }
        });
      },
      async logout({ commit }) {
        const auth = getAuth();
        try {
          await signOut(auth);
          commit("clearUser");
        } catch (error) {
          console.error("Error al cerrar sesión:", error);
        }
      }
    },
    getters: {
      user: (state) => state.user
    }
  });

  function saveState(key, state) {
    window.localStorage.setItem(key, JSON.stringify(state));
  }

  function clearState(key) {
    window.localStorage.removeItem(key);
  }
  
  export default store;
  