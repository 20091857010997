<template>
  <v-container>
    <v-row>
      <v-col>
        <h3>Cargar archivo:</h3>
        <v-file-input v-model="inputFile" @change="handleFileUpload" label="Seleccionar archivo" />
        <v-btn color="primary" @click="uploadFile" :disabled="!selectedFile">Subir archivo</v-btn>
        <br/><br/>
        <h3 v-if="files && files.length > 0">Archivos:</h3>
        <v-text-field v-model="searchQuery" label="Filtrar" />
        <v-list v-if="files && files.length > 0">
          <v-list-item v-for="(file, index) in filteredFiles" :key="index">
            <v-list-item-content>
              <v-list-item-title>
                <a :href="file.url" target="_blank" rel="noopener noreferrer">{{ file.name }}</a>
              </v-list-item-title>
              <v-btn size="x-small" @click="toggleQR(index)" class="mx-2">
                  {{ file.showQR ? "Ocultar QR" : "QR" }}
              </v-btn>
              <br/>
              <qrcode-vue v-if="file.showQR" :value="file.url" :size="180" class="mt-2" />
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { storage } from "../firebase";
import { ref, uploadBytes, listAll, getDownloadURL, getMetadata } from 'firebase/storage';
import QrcodeVue from 'qrcode.vue'

export default {
  components: {
    QrcodeVue
  },
  data() {
    return {
      selectedFile: null,
      files: [],
      inputFile: null,
      showQR: false,
      searchQuery: "",
    };
  },
  methods: {
    handleFileUpload(event) {
      this.selectedFile = event.target.files[0];
    },
    async uploadFile() {
      if (!this.selectedFile) return;

      try {
        const metadata = {
          contentType: this.selectedFile.type,
          customMetadata: {
            userId: this.userId
          }
        };
        const fileRef = ref(storage, this.buildPathFile(this.selectedFile.name));
        await uploadBytes(fileRef, this.selectedFile, metadata);
        alert("Archivo subido con éxito");
        this.fetchFiles();
        this.inputFile = null;
        this.selectedFile = null;
      } catch (error) {
        console.error("Error al subir el archivo:", error);
        alert("Error al subir el archivo");
      }
    },
    async fetchFiles() {
      try {
        const storageRef = ref(storage, this.userPath);
        const res = await listAll(storageRef);
        const filePromises = res.items.map(async item => {
          const url = await getDownloadURL(item);
          const name = item.name;
          const metadata = await getMetadata(item);
          const timeCreated = metadata.timeCreated;
          const showQR = false;
          return { url, name, timeCreated, showQR };
        });
        const fileURLsAndNames = await Promise.all(filePromises);
        this.files = fileURLsAndNames.sort((a, b) => new Date(b.timeCreated) - new Date(a.timeCreated));
      } catch (error) {
        console.error("Error al obtener la lista de archivos:", error);
      }
    },
    toggleQR(filteredIndex) {
      const file = this.filteredFiles[filteredIndex];
      const originalIndex = this.files.findIndex(
        (originalFile) => originalFile.url === file.url
      );

      this.files[originalIndex].showQR = !this.files[originalIndex].showQR;
    },
    buildPathFile(fileName) {
      return this.userPath + "/" +fileName;
    }
  },
  computed: {
    filteredFiles() {
      if (!this.searchQuery) {
        return this.files;
      }

      return this.files.filter((file) =>
        file.name.toLowerCase().includes(this.searchQuery.toLowerCase())
      );
    },
    userId() {
      return this.$store.getters.user;
    },
    userPath() {
      return "user/" + this.$store.getters.user;
    }
  },
  mounted() {
    this.fetchFiles();
  }
};
</script>
