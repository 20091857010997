<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" sm="8" md="6">
        <v-card>
          <v-card-title v-if="!isAuthenticated">
            <span v-if="!isCodeSent">Autenticaci&oacute;n</span>
            <span v-else>Verificar c&oacute;digo</span>
          </v-card-title>
          <v-card-text v-if="!isAuthenticated">
            <v-form ref="form" @submit.prevent="submitForm">
              <v-text-field
                v-if="!isCodeSent"
                label="N&uacute;mero de tel&eacute;fono, ejemplo: +573112142285"
                v-model="phoneNumber"
                type="text"
                required
              ></v-text-field>
              <v-text-field
                v-if="isCodeSent"
                label="C&oacute;digo de verificaci&oacute;n"
                v-model="verificationCode"
                type="text"
                required
              ></v-text-field>
              <v-btn color="primary" type="submit">
                {{ isCodeSent ? "Verificar código" : "Enviar código de verificación" }}
              </v-btn>
            </v-form>
          </v-card-text>
          <v-card-text v-else>
            <p>Ya est&aacute;s autenticado.</p>
          </v-card-text>
          <div id="recaptcha-container" v-if="!isCodeSent"></div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

  
<script>
  import { getAuth, signInWithPhoneNumber, RecaptchaVerifier } from "firebase/auth";
  
  export default {
    data() {
      return {
        phoneNumber: "",
        verificationCode: "",
        isCodeSent: false,
        confirmationResult: null,
        recaptchaVerifier: null,
        auth: null
      };
    },
    methods: {
      async sendVerificationCode() {
        console.log("sendVerificationCode");

        try {
          this.confirmationResult = await signInWithPhoneNumber(this.auth, this.phoneNumber, this.recaptchaVerifier);
          this.isCodeSent = true;
        } catch (error) {
          console.error("Error al enviar el código de verificación:", error);
        }
      },

      async confirmVerificationCode() {
        console.log("confirmVerificationCode");
        try {
          await this.confirmationResult.confirm(this.verificationCode);
          await this.$store.dispatch("checkUserAuth"); 
          this.$router.push("/");
        } catch (error) {
          console.error("Error al verificar el código:", error);
        }
      },
      submitForm() {
        if (!this.isCodeSent) {
          this.sendVerificationCode();
        } else {
          this.confirmVerificationCode();
        }
      },
    },
    computed: {
      isAuthenticated() {
        return !!this.$store.getters.user;
      }
    },
    mounted() {
      this.auth = getAuth();
      this.recaptchaVerifier = new RecaptchaVerifier("recaptcha-container", {}, this.auth);
    }
  };
</script>
  